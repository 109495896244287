import apiService from '../services/api-service';
import { useAuth } from '../providers/auth-provider';

export function useAuthenticatedRequest() {
  const { refreshToken, setRefreshToken, jwt, setJwt, logOut } = useAuth();

  const LOGOUT_API_PATH = '/api/users/log-out';

  return {
    get: makeAuthenticatedRequestFunctionForMethod('get'),
    post: makeAuthenticatedRequestFunctionForMethod('post'),
    put: makeAuthenticatedRequestFunctionForMethod('put'),
    deleteRequest: makeAuthenticatedRequestFunctionForMethod('deleteRequest'),
  };

  function makeAuthenticatedRequestFunctionForMethod(method) {
    const ignoreLoggingOnAuthFailure = refreshToken !== null;
    return function (path, data, headers = {}, responseType) {
      return new Promise((resolve, reject) => {
        return apiService[method](
          path,
          data,
          {
            ...getAuthHeaders(jwt),
            ...headers,
          },
          undefined,
          responseType,
          ignoreLoggingOnAuthFailure
        )
          .then(res => resolve(res))
          .catch(err => {
            if (shouldRequestNewAccessToken(err, path)) {

              if (isRefreshTokenExpired(refreshToken)) {
                logOut();
                reject(err);
              }

              refreshTokens(refreshToken, setRefreshToken, setJwt, jwt)
                .then(updatedJwt => {
                  apiService[method](path, data, getAuthHeaders(updatedJwt))
                    .then(res => resolve(res))
                    .catch(err => reject(err));
                })
                .catch(err => {
                  logOut();
                  reject(err);
                });
            } else {
              reject(err);
            }
          });
      });
    };
  }

  function getAuthHeaders(jwt) {
    return {
      Authorization: `Bearer ${jwt}`,
    };
  }

  function isRefreshTokenExpired(refreshToken) {
    if (refreshToken === undefined || refreshToken === null)
      return true;

    const utcNow = new Date().getTime();
    return refreshToken.exp < utcNow;
  }

  function shouldRequestNewAccessToken(err, path) {
    const isLoggingOut = path === LOGOUT_API_PATH;
    return (
      err.response &&
      err.response.status === 401 &&
      refreshToken !== null &&
      !isLoggingOut
    );
  }

  function refreshTokens() {
    return new Promise((resolve, reject) => {
      apiService
        .post('/api/users/refresh-token', JSON.stringify(refreshToken.token))
        .then(({ data }) => {
          setRefreshToken(data.refresh);
          setJwt(data.access);
          resolve(data.access);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
